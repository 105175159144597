<template>
  <div>
    <section class="section-bg">
      <!-- <div class="jumbotron jumbotron-fluid py-5 banner"></div> -->
      <div class="container py-2 mt-5 text-center text-green">
        <h2>關於公司</h2>
        <small>Company introduction</small>
      </div>
      <div class="row py-5 justify-content-center">
        <div class="col-md-10">
          <div class="card py-5 px-4 mx-4 border_corner">
            <h1 class="h4 mb-0 text-center text-green">羿鴻企業有限公司</h1>
            <div class="py-4 py-md-5 text-md-center">
              <p>讓顧客滿意，並提供專業的服務諮詢、免費的報價服務。</p>
              <p>單一窗口、整合服務、一次滿足需求。</p>
              <p>不僅為您提供符合需求及成本考量的解決方案更為您整合資源，提供適合您的客製化服務。</p>
              <p>歡迎您與我們聯絡，享受貼心與優質的乾淨生活。</p>
            </div>
            <div class="row py-3">
              <div class="col-md-6 text-center">
                <img class="img-fluid company-imgSize" src="../assets/img/member/member2.jpg" alt="">
              </div>
              <div class="col-md-6">
                <div class="card-body">
                  <div class="flex-column pt-3 mb-3 border-bottom">
                    <h6 class="mb-0 text-green">專業員工訓練</h6>
                    <small class="font-italic text-muted">公司提供員工一對一持續且完整的教育訓練</small>
                  </div>
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <p class="">1.	工作環境及內容介紹</p>
                    </li>
                    <li class="nav-item">
                      <p class="">2.	工作安全教育及指導</p>
                    </li>
                    <li class="nav-item">
                      <p class="">3.	工作禮貌要求及執行</p>
                    </li>
                    <li class="nav-item">
                      <p class="">4.	工作技巧及步驟訓練</p>
                    </li>
                    <li class="nav-item">
                      <p class="">5.	工具及設備說明操作</p>
                    </li>
                    <li class="nav-item">
                      <p class="">6.	公司要求及規定說明</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row py-3">
              <div class="col-md-6 align-self-center text-center">
                <img class="img-fluid company-imgSize" src="../assets/img/member/member6.jpg" alt="">
              </div>
              <div class="col-md-6">
                <div class="card-body">
                  <div class="flex-column pt-3 mb-3 border-bottom">
                    <h6 class="mb-0 text-green">員工定期教育訓練</h6>
                    <small class="font-italic text-muted">維持服務品質給客戶最專業的服務</small>
                  </div>
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <p class="">1.	客戶要求說明及溝通</p>
                    </li>
                    <li class="nav-item">
                      <p class="">2.	工作技巧複習及檢視導</p>
                    </li>
                    <li class="nav-item">
                      <p class="">3.	工作安全說明及要求</p>
                    </li>
                    <li class="nav-item">
                      <p class="">4.	現場環境巡檢及糾正</p>
                    </li>
                    <li class="nav-item">
                      <p class="">5.	員工心理溝通及糾正</p>
                    </li>
                    <li class="nav-item">
                      <p class="">6.	緊急應變及現場處理</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
  .banner{
    background-image: url('~@/assets/img/img-2.jpeg');
    // background-position: center center;
  }
</style>
<script>
export default {
  
}
</script>